@font-face {
    font-family: 'mmc';
    src: url('../fonts/mmc.eot?ahov0f');
    src: url('../fonts/mmc.eot?ahov0f#iefix') format('embedded-opentype'),
        url('../fonts/mmc.ttf?ahov0f') format('truetype'),
        url('../fonts/mmc.woff?ahov0f') format('woff'),
        url('../fonts/mmc.svg?ahov0f#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

.flaticon-woman:before {
    font-family: 'mmc';
    content: "\e902";
}

.flaticon-pregnant:before {
    font-family: 'mmc';
    content: "\e900";
}

.flaticon-baby:before {
    font-family: 'mmc';
    content: "\e901";
}

.zalo-chat-widget {
    bottom: 25px !important;
    right: 85px !important;
}

.mobile_only {
    display: none;
}

@media only screen and (max-width: 768px) {
    .mobile_only {
        display: block;
    }
}