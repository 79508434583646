@font-face {
  font-family: mmc;
  src: url("mmc.da4b2aff.eot");
  src: url("mmc.da4b2aff.eot#iefix") format("embedded-opentype"), url("mmc.6b9f9e2c.ttf") format("truetype"), url("mmc.441e60df.woff") format("woff"), url("mmc.c45a6bc1.svg#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.flaticon-woman:before {
  content: "";
  font-family: mmc;
}

.flaticon-pregnant:before {
  content: "";
  font-family: mmc;
}

.flaticon-baby:before {
  content: "";
  font-family: mmc;
}

.zalo-chat-widget {
  bottom: 25px !important;
  right: 85px !important;
}

.mobile_only {
  display: none;
}

@media only screen and (max-width: 768px) {
  .mobile_only {
    display: block;
  }
}

/*# sourceMappingURL=index.22fc5e23.css.map */
